.header {
  padding: 0;
}

.header-centered {
  font-size: medium;
  font-weight: bold;
  padding-left: 5px;
  padding-bottom: 10px;
}

.steps {
  padding-top: 10px;
  padding-bottom: 15px;
}

.steps-content {
  margin-top: 16px;
  border: 0px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fff;
  text-align: left;
  padding: 12px;
}

.sidebar-content {
  height: 100%;
}

.main-layout {
  background: #fff;
}

.main-content {
  margin: 0;
  padding: 0;
  background: #fff;
}

.ant-steps-item-process .ant-steps-item-icon {

  background: $primary;
}


.ant-steps-item-title {
  font-family: 'BMW Group One';
  font-size: 16px;
}


.steps-content {
  margin-top: 1px;
  border: 0px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fff;
  text-align: left;
  padding: 12px;
}


.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #607d8b;
  border-color: #607d8b;
  font-family: 'BMW Group One';
  font-weight: bold;
  font-size: 14px;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  background-color: $white;
  border-color: $success;
  border-width: 2px;
  color: $success;
  font-family: 'BMW Group One';
  font-weight: bold;
  font-size: 14px;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: $success;
  font-family: 'BMW Group One';
  font-weight: bold;
  font-size: 14px;
}


