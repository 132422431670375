// Font Smoothing
html,
html a,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  text-rendering: optimizeLegibility !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);

  --antd-wave-shadow-color: rgb(0 0 0 / 85%);
  --scroll-bar: 0;

}

// Background colour where breadcrumb is situated
:global(.ant-layout) {
  background: #f8f8f8 !important;
}

// Variables
@import 'variables/variables';

// Theme
@import 'theme/colors';
@import 'theme/typography';

// Pages
@import 'pages/requestdetails';
@import 'pages/requestforms';
@import 'pages/users';
@import 'pages/assign';
@import 'pages/surveymasterdata';

// Skin
@import 'skin/buttons';
@import 'skin/cards';
@import 'skin/steps';
@import 'skin/tables';
@import 'skin/forms';
@import 'skin/tabs';
@import 'skin/fileupload';
@import 'skin/modals';
@import 'skin/menus';
@import 'skin/progress';
@import 'skin/tooltips';
@import 'helpers/helpers';

// Help button to open user manual
.help-link {
  position: absolute;
  top: 8px;
  right: 14px;
  width: 131px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #c2c2c2;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0px 15px;
  color: $gray-600;
  transition: background-color 0.4s ease;

  &:hover {
    background: $brown-700;
    color: $gray-600;
  }
}

