.ant-progress-custom {
  .ant-progress {
    &-inner {
      background: $brown-600;
      border-radius: 0;
    }

    &-bg {
      background: $blue-800;
    }
  }
}

.ant-progress-status-success .ant-progress-bg {
  background: $blue-800;
}
