.site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
    margin-bottom: 10px;
    overflow: hidden;
    background: #024051;
    border: 0px;
    border-radius: 2px;
}
.title-header-card {
    background-color: #024051;
    color: rgba(19, 18, 18, 0.55);
    cursor: pointer;
    margin-bottom: 16px;
    position: relative;
    transition: none;
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

.title-header-card-text {
    position: center;
    font-family: 'BMW Group Condensed';
    font-size: calc((13vw - 4.5rem) / 7);
    font-weight: bold;
    color: rgba(19, 18, 18, 0.55);
    overflow: hidden;


}
.menu-main-card {
    position: center;
    display: table-cell;
    vertical-align: middle;

}

.menu-main-card-text {
    font-family: 'BMW Group Condensed';
    font-size: x-large;
    font-weight: bold;
    color: #024051;;


}
#title-header-card-image {
    width: 100%;
    height: 100%;
    background: url(../images/welcome-image.PNG) no-repeat center;
    position: relative;
}


.landing-page-card {
    display: inline-block;
    margin-bottom: 16px;
    background-color: #024051;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
    transition: none;
    width: 100%;
}

.landing-page-card::after {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: none;
    width: 100%;
    z-index: -1;
}

.landing-page-card:hover {
    transform: scale(1.02, 1.02);
}

.landing-page-card:hover::after {
    opacity: 1;
}

.card .view.gradient-card-header {
    padding: 1rem 1rem;
    text-align: center;
}

.card h3,
.card.card-cascade h4 {
    margin-bottom: 0px;
}

.welcome-page-carousel {
    height: 160px;
    color: #fff;
    line-height: 160px;
    text-align: center;
    background: #024051;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-family: 'BMW Group Light';
    color: #024051;
    position: static;
    padding: 12px 16px;
    padding-left: 10px;
    line-height: 1.5715;
    cursor: pointer;
    width: 100%;
    transition: all 0.3s, visibility 0s;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 1px;
}

.welcome-page-carousel-links {
    font-family: 'BMW Group Condensed';
    font-size: small;
    font-weight: bold;
    color: #024051;
}

.ant-card {
    box-sizing: border-box;
    margin: 5px;
    padding-bottom: 0px;
    color: #024051;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    background: #ffffff;
    border-radius: 0px;
}

a {
    color: #ffffff;
    font-family: 'BMW Group Condensed';
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
}


.link-black, .link-black:visited {
    color: black;
}

.ant-form-item-label {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
}

.ant-table-thead > tr > th {
    position: relative;
    color: #024051;
    font-weight: bold;
    text-align: left;
    background: #c6c6c6;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}

.ant-input[disabled] {
    color: #c6c6c6;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.ant-select[disabled] {
    color: #c6c6c6;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

a.ant-picker-now-btn {
    color: #1677ff;
}

