.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: "BMW Group One";
    min-width: 400px;
}


.styled-table thead tr {
    background: #8296b2;
    color: white;
    border-color: #ffffff;
    font-family: "BMW Group Light";
    font-weight: bold;
    font-size: 12px;
}

.styled-table th {
    padding: 8px 10px;
}

.styled-table td {
    padding: 4px 6px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #607d8b;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #607d8b;
}