.header {
  padding: 0;
}

.header-centered {
  font-size: medium;
  font-weight: bold;
  padding-left: 5px;
  padding-bottom: 10px;
}

.steps {
  padding-top: 10px;
  padding-bottom: 15px;
}

.steps-content {
  margin-top: 16px;
  border: 0px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fff;
  text-align: left;
  padding: 12px;
}

.sidebar-content {
  height: 100%;
}

.main-layout {
  background: #fff;
}

.main-content {
  margin: 0;
  padding: 0;
  background: #fff;
}

.ant-steps-item-process .ant-steps-item-icon {

  background: $primary;
}


.ant-steps-item-title {
  font-family: 'BMW Group Condensed';
  font-size: 14px;
}


.steps-content {
  margin-top: 1px;
  border: 0px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fff;
  text-align: left;
  padding: 5px;
}


.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #607d8b !important;
  border-color: #607d8b !important;
  font-family: 'BMW Group One';
  font-weight: bold;
  font-size: 10px;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  background-color: $white !important;
  border-color: $success !important;
  border-width: 2px;
  color: $success;
  font-family: 'BMW Group One';
  font-weight: bold;
  font-size: 10px;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: $success;
  font-family: 'BMW Group Condensed';
  font-weight: normal;
  font-size: 10px;
}

.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: bold;
}

.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: #024051;
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-card {
  box-sizing: border-box;
  margin: 5px;
  padding-bottom: 0px;
  color: #024051;
  font-size: 14px;
  font-variant: tabular-nums;
  font-family: 'BMW Group Condensed';
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum', "tnum";
  position: relative;
  background: #ffffff;
  border-radius: 0px;
}

.ant-card-bordered {
  border: 0px solid #f0f0f0;
}

.ant-tabs-tab-btn {
  margin-bottom: -1px;
  padding: 0 24px;
  color: #024051;
  font-weight: 500;
  font-size: 14px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  font-variant: tabular-nums;
  font-family: 'BMW Group Condensed';
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #607d8b !important;
  text-shadow: 0 0 0.25px currentColor;
  font-family: "BMW Group Condensed";
  font-size: 14px;
  font-feature-settings: 'tnum', 'tnum', "tnum";
}
