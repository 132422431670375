.searchContainer {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 45% 45%;
}

.poFlipHeading [class='ant-collapse-item'] {
    border:none;
}

.poFlipHeading [class='ant-collapse-content-box'] {
    margin-left:24px;
}

.poFlipHeading [class*='ant-collapse'] {
    border:none;
    background: transparent;
}

.poFlipHeading [class='ant-collapse-header-text'] {
    font-size:16px;
    font-weight: normal;
}