.peppolLabelWithTwoFieldsContainer {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 45% 45%;
}

.formItemVDAMandatory::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.yesNoStyling {
    width:100%;
}

.yesNoStyling label {
    width:50%;
}
