

  .ant-steps-item-process .ant-steps-item-icon {
    background: $primary !important;
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: $white !important;
      border-color: $success !important;
      border-width: 2px;

      > .ant-steps-icon {
        background-color: $white !important;
        color: $success !important;
      }
    }
  }

  .request-forms {
    .ant-steps-item-title {
      font-family: 'BMW Group One';
      font-size: 16px;
    }
  }

  .requests-process {
    .ant-steps-item-title {
      // color:#92A2BD!important;
      color: $gray-500 !important;
      font-family: 'BMW Group One';
      font-size: 14px;
      font-weight: normal;
    }

    .ant-steps-item-wait .ant-steps-item-icon {
      // background: lighten($primary, 45);
      // border-color: $primary;
      background: white;
      border: 2px solid #92a2bd;

      .ant-steps-icon {
        color: $primary;
        font-family: 'BMW Group One';
        // font-family: $bmw-group;
        font-weight: bold;
        font-size: 14px;
        color: #92a2bd;
      }
    }
  }


