
.details-page {
  height: calc(100vh - 112px);
}


.details-layout {
  background: $white;
  margin: 35px 170px;
  margin-top: 0;
  padding: 40px;
  padding-bottom: 15px;

  @media only screen and (max-width: 1200px) {
    margin: 15px 0;
    margin-top: 15px;
    padding: 30px;
  }

  @media (max-width: 600px) {
    margin: 15px 0;
  }
}


.process-steps {
  margin: auto;
  margin-top: 0;
  @media only screen and (max-width: 1023px) {
    border: 2px solid $gray-300;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
  }
}

.request-steps-vertical {
  margin-top: 45px;

  @media only screen and (max-width: 1023px) {
    margin-top: 0;
  }

  &-disabled {
    filter: grayscale(10);
    opacity: 0.4;

    > .ant-upload {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .request-status {
    font-size: 1em;
    font-weight: bold;
  }

  &-steps {
    margin-bottom: 60px;
    position: relative;

    &:last-of-type {
      margin-bottom: 15px;
    }

    @media only screen and (max-width: 1023px) {
      margin-bottom: 27px;
    }

    img {
      display: inline-block;
      max-width: 24px;
    }

    span {
      color: $primary;
      font-size: 14px;
      font-weight: normal;
      text-transform: none;

      + span {
        margin-bottom: -14px;
        margin-left: 29px;
        text-transform: none;

        small {
          display: block;
          line-height: 14px;
          margin-top: 0;
          @media only screen and (max-width: 1023px) {
            color: $gray-500;
            font-size: 0.7rem;
            margin-top: 0;
          }
        }
      }
    }

    &::after {
      background: $gray-400;
      content: '';
      display: block;
      height: 46px;
      left: 11px;
      position: absolute;
      top: 32px;
      width: 1px;
      @media only screen and (max-width: 1023px) {
        background: $gray-400;
        height: 15px;
        top: 30px;
      }
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
}

.badge {
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  padding: 0.3em 0.8em;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: normal;
  text-transform: initial;

  &:empty {
    display: none;
  }

  &.badge-warning {
    background-color: $orange;
    color: $white;
  }

  &.badge-danger {
    background-color: $red;
    color: $white;
  }

  &.badge-cancel {
    background-color: $black;
    color: $white;
  }
}

