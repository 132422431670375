.ag-pinned-right-cols-container {
  .amberIndicator,
  .redIndicator {
    &::before {
      content: '';
      display: none;
    }
  }
}

.amberIndicator,
.redIndicator {
  &::before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 5px;
  }
}

.amberIndicator {
  &::before {
    background: $orange;
  }
}

.redIndicator {
  &::before {
    background: $red;
  }
}

.ag-header {
  border: 0;
}

.ag-header-row {
  background: $blue-700;
  font-weight: bold;
}

.ag-header-cell {
  &:hover {
    background: $blue-800;
  }
}

.grid-cell-right {
  text-align: right;
}

.grid-cell-center {
  text-align: center;
}
