.reportTypeSelector {

}

.partnerSelector {

}

.companySelector {

}

.invoiceStatusSelector {

}

.selfBilledSimpleSearchContainer {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 45% 45%;
}

.kpiSearchContainer {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 30% 5% 55%;
}

.kpiDateOptions {

}

.kpiDateOptions [class~=ant-col] {
    width:100%;
    max-width:100%;
}

.kpiDateOptions [class~=ant-radio-button-wrapper] {
    width:25%;
    font-size:13px;
}

.kpiReportUsageContainer {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.selfBilledExpertSearchContainer {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 30% 30% 30%;
}

.paymentsExpertSearchContainer {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 23% 23% 23% 23%;
}

.singleColumnGrid {

}

.deliverySelection {

}

.businessAreaSelection {

}

.fromToDateSelection {
    display:grid;
    grid-template-columns: 42% 12% 42%;
    grid-template-rows: 34px 31px;
}

.fromToDateSelectionLeftAligned {
    display:grid;
    column-gap: 3px;
    grid-template-columns: 27% 34% 34%;
}

.fromToDateSelection [class~=ant-picker-input] input {
    font-size:0.95em;
}

.inputWithOperator {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 35% 60%
}

.inputWithOperatorLeftLabel {
    display: grid;
    grid-template-columns: 28% 30% 39%
}

.simpleSearchButtons {
    margin-top:20px;
    margin-left:30px;
}

.simpleSearchButtons button {
    margin-right:5px;
}

.simpleFlipRefresh {
    transform: scaleX(-1);
}

.selectReportTypeView {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 20% 80%;
    padding: 15px;
}

.reportTypeDropdown {
    display: grid;
    grid-template-columns: 50%;
}

.bmwGeneralSimpleSearch {
    display: grid;
    grid-template-columns: 100%;
}

.mandatoryAsterisk {
    color:red;
}
.pseudoMandatory {
}

p.pseudoMandatory::after {
    content:'*';
    color: red;
}

span.pseudoMandatory::after {
    content:'*';
    color:red;
}

.reportDataGrid {
    margin-top: 20px;
}

.reportDataGridButtons {
    margin-left: 35px;
}

.reportGridFullscreen {
    position: absolute;
    top: 10px;
    width: 100%;
    overflow-x: hidden;
    height: 700px;
    margin: 0px;
    padding: 0px;
    left: 0px;
    z-index: 100;
    background-color: white;
}

[class~=info-drop-down-label],
[class~=info-radio-label],
[class~=info-portal-single-selection-heading],
[class~=info-radio],
[class~=from-heading],
[class~=to-heading],[class~=input-with-operator-label] {
    font-family: 'BMW Group Condensed';
}
[class~=info-radio]:last-child {
    width:300px;
}

[class~=info-drop-down-label][class~=left] {
    width:27%;
    display:inline-block;
}
[class~=info-portal-input-with-operator] [class~=ant-input-number] {
    width:99%;
}

[class~=info-portal-input-with-operator] [class~=ant-input-affix-wrapper] input {
    border-bottom: none !important;
}

.sr5DatePicker {
    display:inline-block;
    width:70%;
}

.infoPortalDatePicker {
    display:inline-block;
    width:68.5%;
    margin-left:1.5%;
    vertical-align: top;
}

.pageSizeSelector {
    font-size: 12px;
    color:rgba(0, 0, 0, 0.54);
}

.reportConfigGrid {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 30% 60%;
    margin-top: 10px;
}