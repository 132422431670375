// Color system

$white: #fff !default;
$gray-100: #f8f8f8 !default;
$gray-200: #e9ecef !default;
$gray-300: #e6e6e6 !default;
$gray-400: #d3d3d3 !default;
$gray-500: #999999 !default;
$gray-600: #666666 !default;
$gray-700: #444444 !default;
$gray-800: #333333 !default;
$gray-900: #222222 !default;
$black: #000 !default;

// BMW Oceans
$ocean700: #035970 !default;

// BMW Browns
$brown-600: #e5e3dd !default;
$brown-700: #d4d1c6 !default;
$brown-800: #c3beb0 !default;
$brown-900: #aaa38e !default;

$browns: () !default;
$browns: map-merge(
                (
                        '600': $brown-600,
                        '700': $brown-700,
                        '800': $brown-800,
                        '900': $brown-900,
                ),
                $browns
);

// BMW Blue
$blue-600: #e9ebf1 !default;
$blue-700: #d2dae3 !default;
$blue-800: #bdc6d6 !default;
$blue-900: #5b708f !default;

$blues: () !default;
$blues: map-merge(
                (
                        '600': $blue-600,
                        '700': $blue-700,
                        '800': $blue-800,
                        '900': $blue-900,
                ),
                $blues
);

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
                (
                        '100': $gray-100,
                        '200': $gray-200,
                        '300': $gray-300,
                        '400': $gray-400,
                        '500': $gray-500,
                        '600': $gray-600,
                        '700': $gray-700,
                        '800': $gray-800,
                        '900': $gray-900,
                ),
                $grays
);

$blue: #617899 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d20000 !default;
$orange: #f5aa18 !default;
$yellow: #ffc107 !default;
$green: #3db014 !default;
$teal: #00c894 !default;
$cyan: #17a2b8 !default;

$light-blue: #e9ebf1 !default;
$dark-blue: #0f2d69 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
                (
                        'blue': $blue,
                        'indigo': $indigo,
                        'purple': $purple,
                        'pink': $pink,
                        'red': $red,
                        'orange': $orange,
                        'yellow': $yellow,
                        'green': $green,
                        'teal': $teal,
                        'cyan': $cyan,
                        'white': $white,
                        'gray': $gray-600,
                        'gray-dark': $gray-800,
                        'light-blue': $light-blue,
                ),
                $colors
);

$primary: $ocean700 !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$light-primary: $light-blue !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
                (
                        'primary': $primary,
                        'secondary': $secondary,
                        'success': $success,
                        'info': $info,
                        'warning': $warning,
                        'danger': $danger,
                        'light': $light,
                        'dark': $dark,
                        'light-primary': $light-blue,
                ),
                $theme-colors
);
