.ant-modal {
  .ant-modal-close-x {
    line-height: 50px;
  }

  .ant-modal-header {
    border-bottom: 4px solid $primary;
    padding: 12px 24px;

    .ant-modal-title {
      font-family: 'BMW Group One';
      font-weight: bold;
    }
  }

  &.dialog-warning {
    .ant-modal-header {
      border-bottom-color: $orange;
    }
  }

  .ant-modal-body {
    font-size: 14px;

    .ant-legacy-form-item-control {
      line-height: 30px;
    }

    .ant-input {
      background: $gray-100;
      border: 0;
      border-bottom: 1px solid $gray-600;
    }
  }

  .ant-modal-footer {
    border-top: 0;

    .ant-btn {
      border-radius: 0;
      font-family: 'BMW Group One';
      font-weight: bold;
      text-transform: uppercase;
    }

    .ant-btn-primary {
      background: $primary;
    }
  }

  .ant-legacy-form-item {
    margin-bottom: 0;
  }

  label {
    display: block;
    font-size: 13px;
    margin-bottom: 2px;
  }

  // Error messages
  .has-error {
    &.ant-input {
      background-color: #d200001a;
      border-bottom: 1px solid $red !important;
    }
  }

  .has-error .ant-input {
    background-color: #d200001a;
    border-bottom: 1px solid $red !important;

    &:hover {
      background-color: #d200001a;
      border-bottom: 1px solid $red !important;
    }
  }

  .has-error {
    .ant-select-selection {
      background-color: #d200001a;
      border-bottom: 1px solid $red !important;
    }
  }

  .cancel-error {
    color: $red;
    font-size: 0.8em;
    margin-top: 2px;
  }

  .has-error {
    .ant-legacy-form-explain,
    .ant-legacy-form-split,
    .cancel-error {
      color: $red;
      font-size: 0.8em;
      margin-top: 2px;
    }
  }
}
