
.survey-buttons-text {
    position: center;
    font-family: 'BMW Group Condensed';
    font-size: calc((2vw - 0.5rem) / 2);
    font-weight: bold;
    color: rgba(19, 18, 18, 0.55);
    overflow: hidden;


}