s .new-request-page {
  height: calc(100vh - 112px);
}

.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  color: #415966;
  font-family: 'BMW Group Condensed';
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #607d8b !important;
  text-shadow: 0 0 0.25px currentColor;
  font-family: 'BMW Group Condensed';
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #607d8b;
  font-family: 'BMW Group Condensed';
}

.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:active,
.ant-tabs-tab-remove:focus {
  color: #415966;
}

.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}

.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  width: 15px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}

.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'BMW Group';
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #f8f8f8;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.ant-input-number {
  font-family: 'BMW Group';
  font-variant: tabular-nums;
  font-size: 14px;
}

.request-blocks-container {
  display: flex;
  justify-content: flex-start;

  @media only screen and (max-width: 768px) {
    display: block;
  }

  .ant-card-custom {
    background: lighten($primary, 45%);
    border: 1px solid $primary;
    margin: 10px;
    max-width: 150px;

    @media only screen and (max-width: 768px) {
      margin: auto;
      margin-bottom: 5px;
      width: 100%;
    }

    .ant-card-body {
      padding: 15px;

      @media only screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        padding: 5px;
      }

      .request-blocks {
        svg {
          max-height: 60px;
        }
      }

      .request-blocks {
        @media only screen and (max-width: 768px) {
          svg {
            display: block;
            margin: 0 15px;
            width: 40px !important;
          }
        }
      }

      .ant-radio-wrapper-checked {
        background: #4758701f;
      }
    }

    .request-description {
      text-align: center;

      @media only screen and (max-width: 768px) {
        margin-right: 15px;
        max-width: 200px;
        text-align: left;
      }
    }
  }
}

.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: $primary !important;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
}

.users {
  background: $white;

  .status-circle {
    background: #e3e7ed;
    border: 1px solid $primary;
    border-radius: 50%;
    color: $primary;
    display: inline-block;
    font-size: 0.8em;
    height: 18px;
    line-height: 16px;
    margin-right: 5px;
    text-align: center;
    width: 18px;
  }

  // Custom Progress Bar
  .ant-progress-custom {
    .ant-progress {
      &-bg {
        height: 18px !important;
      }
    }
  }

  .to-do-item {
    // font-family: $bmw-group;
    background: $white;
    height: 100%;
    padding: 25px 10px 10px;
    position: relative;

    // Title links ant overrides
    .ant-anchor {
      .ant-anchor-ink {
        display: none;
      }

      .ant-anchor-link {
        padding: 5px 0;
      }

      .ant-anchor-link-title {
        color: $primary;

        &:hover {
          // text-decoration: underline;
          color: darken($primary, 15%);
        }
      }
    }

    &-amber,
    &-red {
      &:before {
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 5px;
      }
    }

    &-amber {
      &::before {
        background: $orange;
      }
    }

    &-red {
      &::before {
        background: $red;
      }
    }

    .status {
      color: $gray-500;
      font-size: 0.9em;
      max-width: 90%;
      position: absolute;
      text-transform: uppercase;
      top: 10px;

      &.estimation-info {
        @extend %text-truncate;
        color: $blue;
      }

      &.estimation-expire {
        @extend %text-truncate;
        color: $orange;
      }

      &.payment-required {
        @extend %text-truncate;
        color: $red;
      }
    }

    .to-do-title,
    .to-do-id,
    .to-do-date {
      display: block;
    }

    .to-do-title {
      @extend %text-truncate;
      color: $black;
      font-size: 16px;
      font-weight: bold;
      margin-right: 80px;
      @media only screen and (min-width: 1023px) {
        font-size: 20px;
      }
    }

    .to-do-id {
      @extend %text-truncate;
      color: $gray-600;
      font-size: 13px;
      max-width: 245px;
    }

    .to-do-date {
      color: $gray-500;
      font-size: 12px;
      margin-top: 10px;
    }

    .to-do-price {
      color: #000;
      font-size: 16px;
      font-weight: bold;
      position: absolute;
      right: 15px;
      top: 28px;

      @media only screen and (min-width: 1023px) {
        font-size: 20px;
      }
    }

    .to-do-approved,
    .to-do-rejected,
    .to-do-payment-required {
      @extend %text-truncate;
      font-size: 0.9em;
      font-weight: bold;
      padding: 5px 0;
    }

    .to-do-approved {
      color: $green;
    }

    .to-do-payment-required {
      color: $orange;
    }

    .to-do-rejected {
      color: $gray-500;
    }

    .ant-btn {
      border-radius: 0;
      font-family: 'BMW Group One';
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .requests-process {
    @media only screen and (max-width: 766px) {
      margin-bottom: 0;
      margin-top: 15px;
    }
  }

  .users-tabs {
    position: relative;

    @media only screen and (max-width: 766px) {
      .ant-tabs-bar {
        margin: 0 !important;
      }
    }
  }

  // Financial year drop-down
  .financial-year {
    position: absolute;
    right: 0;
    top: 0;
  }

  .finance-year-select {
    .ant-select-selection-item {
      font-size: 12px;
      line-height: 35px;
    }
  }

  @media only screen and (max-width: 766px) {
    .new-request-btn {
      width: 100%;
    }
  }

  .fullscreen {
    > div {
      height: calc(100vh - 305px) !important;

      div:first-of-type {
        margin-top: 0 !important;
      }

      .ag-toolbar {
        margin-bottom: 0 !important;
      }

      @media only screen and (max-width: 1023px) {
        height: 300px !important;
      }
    }

    .ag-theme-balham {
      .ant-btn {
        top: 2px;
        background: transparent;
        border: 0;
      }

      .ag-cell {
        border: none !important;
      }

      .ag-row {
        border-color: #e4e4e4;
      }

      .ag-cell-label-container {
        @media only screen and (max-width: 768px) {
          font-size: 0.7em;
        }
      }

      @media only screen and (max-width: 768px) {
        font-size: 0.7em;
      }
    }

    .report-rows {
      .ag-cell {
        line-height: 45px;
      }

      height: 45px;

      select {
        background: transparent;
        padding-bottom: 5px;
        border-bottom: 1px solid $gray-400;
      }
    }
  }

  // Reports tab height adjustment for fullscreen calculations
  .ant-tabs-tabpane:nth-of-type(2) {
    .fullscreen {
      > div {
        height: calc(100vh - 276px) !important;
      }
    }
  }

  @media only screen and (min-width: 1023px) {
    .scrollArea {
      margin-top: 15px;
      max-height: calc(100vh - 234px);
      overflow-y: scroll;
      padding-right: 15px;
    }
  }

  // My To Do List
  .my-to-do-list {
    background: #f8f8f8;
    padding: 15px;
    height: calc(100vh - 112px);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $gray-100 inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .ant-spin-dot-item {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: $primary !important;
    border-radius: 100%;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    opacity: 0.3;
  }

  .dashboard-statistics-card {
    position: relative;
    padding: 30px;
    background: #ececec;
  }
}
