.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #ffffff;
}

.ant-menu {
  box-sizing: border-box;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', 'tnum';
  margin: 0;
  padding: 0;
  color: #fafafa !important;
  font-size: 12px;
  text-align: left;
  list-style: none;
  background: #024051 !important;
  outline: none;
  pointer-events: auto;
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #024051;
  color: #fafafa;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #fafafa !important;
  background-color: #024051;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #fafafa;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}

.ant-pro-global-footer {
  float: inherit;
  padding-left: 40%;
  display: inline-flex;
  padding-bottom: 10px;
  color: #024051;
  font-size: 14px;
  background: #f0f2f5;
}

.ant-pro-global-footer-links {
  padding-right: 5px;
  color: #024051;
}

h1.ant-typography,
.ant-typography h1 {
  margin-bottom: 0.5em;
  font-family: 'BMW Group Condensed';
  color: #024051;
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
  padding-left: 100px;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-item-active {
  color: #fafafa;
  background-color: #024051;
}

.ant-divider.ant-divider-horizontal {
  border-top: 5px solid #024051;
  margin-top: -3.9em;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
  color: #fafafa !important;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu-item:not(.ant-menu-inline),
ant-menu-submenu-open,
ant-menu-submenu-active,
ant-menu-submenu-title:hover {
  color: #fafafa !important;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
ant-menu-submenu:hover > ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #fafafa !important;
}

.ant-menu-submenu-title {
  color: #fafafa;
}

.ant-menu-submenu-expand-icon-content a {
  color: #fafafa;
}


.ant-menu-submenu-arrow-content a {
  color: #fafafa;
}

.ant-menu-submenu-title-content a {
  color: #fafafa;
}

.ant-menu-item a {
  color: #fafafa;
}

.ant-menu-item a:hover {
  color: #fafafa;
}

.ant-menu .ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  inset-inline-end: 16px;
  width: 10px;
  color: #fafafa;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s;
}

.my-prefix-base-menu-inline-item-title  {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  color: #fafafa;
}

.ant-menu-sub.ant-menu-inline {
  padding: 0;
  color: #fafafa;
  background: #024051;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}



















.my-prefix-base-menu-inline-item-title .my-prefix-base-menu-inline-item-text {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
}

.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  color: #fafafa;
}