
.space-align-container {
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    float: right;
    padding: 1px 40px 1px;
}

.space-align-block {
    flex: none;
    margin: 1px 1px;
    padding: 1px;
}

.space-align-block .mock-block {
    align-items: end;
    display: inline-block;
    padding: 1px 1px 1px;
    background: rgba(150, 150, 150, 0.2);
}

.ant-form-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    margin-bottom: 5px;
    vertical-align: top;
}

.bold-row {
    font-weight: bold;
    /* background: 'black'  */
}