.ant-tabs-nav::before {
  border-bottom: 2px solid $gray-300;
  content: '';
}

.ant-tabs-tab {
  font-weight: bold;

  &-btn {
    font-weight: bold;
  }

  &-active {
    color: $primary;
  }
}

.ant-tabs-ink-bar {
  background: $primary;
  height: 2px;
}
