//Local Uploads

.ant-upload-list-item {
  width: 408px;
}

.ant-anchor-link {
  padding-left: 20px;
}

.ant-anchor-ink {
  &:before {
    background: transparent;
    background-image: url('../../../images/paper-clip.svg');
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    top: 8px;
    left: 2px;
  }
}

.ant-upload-list-item-card-actions {
  right: -5px;

  button {
    background: transparent;

    &:hover {
      color: transparent;
    }

    &:before {
      opacity: 0.7;
      content: '';
      display: block;
      background-image: url('../../../images/close.svg');
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;

      &:hover {
        opacity: 0.9;
      }
    }

    svg {
      display: none;
    }
  }
}

.anticon-delete {
  svg {
    &:before {
      content: 'test';
    }
  }
}

// Request Documents
.request-documents-row {
}

.request-documents-upload-btn {
  border: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
}

.ant-upload {
  &-drag {
    background: transparent;
  }
}

.ant-upload-list {
  margin-top: 15px;
  // &-item {
  //     background: $gray-100;
  //     &:hover {
  //         background: $gray-200;
  //     }
  // }
  // &-item-info {
  //     &:hover {
  //         background: $gray-200;
  //     }
  // }
  &-item-error {
    background-color: #fae5e5;

    .ant-upload-list-item-info {
      width: 50%;

      &:hover {
        background-color: #fae5e5;
      }
    }
  }

  &-item-card-actions {
    &-btn {
      opacity: 1;
    }
  }
}

.spinner-icon {
  font-size: 34px;
  color: #d1d1d1;
}
