.ant-btn {
  border-radius: 0;
  font-family: 'BMW Group One';
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

  &.ant-btn-primary {
    background: $primary;
    border-color: $primary;

    &:hover {
      background: #035970;
    }

    &.ant-btn-primary[disabled] {
      background: #035970;
      color: $white;
      opacity: 0.5;

      &:hover {
        background: #035970;
        opacity: 0.5;
      }
    }
  }

  &.ant-btn-default {
    border: 1px solid #c2c2c2;
    font-weight: bold;

    &:hover {
      background: $brown-700;
      color: inherit;
    }

    &.ant-btn-default[disabled] {
      background: $white;
      border: 1px solid #c2c2c2;
      color: $gray-700;
      opacity: 0.5;

      &:hover {
        background: $white;
        color: $gray-700;
      }
    }
  }
}
