
.survey-document-type-main-visible {
    visibility: visible;
}

.survey-document-type-main-hidden {
    visibility: hidden;
}

.survey-document-type-main-details-visible {
    visibility: visible;
}

.survey-document-type-main-details-hidden {
    visibility: hidden;
}

/*
a, a:hover, a:visited, a:active {
    color: rgb(68, 68, 68);
    text-decoration: none;
}*/
