@font-face {
  font-family: 'BMW Group One';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/cp-fonts/bmwgroupcondensed-regular-webfont.eot');
  src: url('../../fonts/cp-fonts/bmwgroupcondensed-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/cp-fonts/bmwgroupcondensed-regular-webfont.woff2') format('woff2'),
  url('../../fonts/cp-fonts/bmwgroupcondensed-regular-webfont.woff') format('woff'),
  url('../../fonts/cp-fonts/bmwgroupcondensed-regular-webfont.ttf') format('truetype'),
  url('../../fonts/cp-fonts/bmwgroupcondensed-regular-webfont.svg#bmw_group_condensedregular') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'BMW Group';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/cp-fonts/BMWGroup-Regular.eot');
  src: url('../../fonts/cp-fonts/BMWGroup-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/cp-fonts/BMWGroup-Regular.woff2') format('woff2'),
  url('../../fonts/cp-fonts/BMWGroup-Regular.woff') format('woff'),
  url('../../fonts/cp-fonts/BMWGroup-Regular.svg#BMWGroup-Regular') format('svg');
}

.portal,
h1,
h2,
h3,
h4 {
  font-family: $bmw-condensed-regular;
}

.p {
  font-size: $default-font-size;
}

p {
  // font-family: Arial;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 20px;
}

h1 {
  // color: $blue;
  color: #92a2bd;
  // font-size: 32px;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-transform: uppercase;

  @media only screen and (min-width: 600px) {
    // font-size: 40px;
    font-size: 32px;
    line-height: 40px;
  }
}

h2 {
  color: $gray-700;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;

  @media only screen and (min-width: 600px) {
    font-size: 32px;
    line-height: 32px;
  }
}

h3 {
  color: $gray-700;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;

  @media only screen and (min-width: 600px) {
    font-size: 24px;
  }
}

h4 {
  color: $gray-700;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;

  @media only screen and (min-width: 600px) {
    font-size: 18px;
    line-height: 24px;
  }
}

h5 {
  color: $gray-700;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  @media only screen and (min-width: 600px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

%text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  margin-top: 1rem;
}
