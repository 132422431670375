.font-weight-bold {
  font-weight: bold;
}

.text-muted {
  color: $gray-500;
}

.text-black {
  color: $black;
}

// Display Block Elements
.d-block {
  display: block;
}

.d-none {
  display: none !important;
}

@media only screen and (min-width: 1023px) {
  .d-md-block {
    display: block !important;
  }
  .d-md-none {
    display: none !important;
  }
}

// Text Alignments
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@media only screen and (min-width: 767px) {
  .text-sm-right {
    text-align: right;
  }
}

// Floats
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.clearfix::after {
  clear: both;
  content: '';
  display: block;
}

// Flexbox
.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.align-items-end {
  align-items: flex-end !important;
}

// Colors
.bg-light {
  background: $gray-100;
}

.bg-white {
  background: $white;
}

// Width and Height
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .w-sm-100 {
    text-align: right;
  }
}

@media only screen and (min-width: 1024px) {
  .h-md-100 {
    height: 100%;
  }
}

// Images
.img-fluid {
  height: auto;
  max-width: 100%;
  width: 100%;
}

.scaling-svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

// Margins and Paddings

.my-auto {
  margin-bottom: auto;
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

$spaceamounts: (-16, -10, -2, -5, 0, 3, 5, 8, 10, 15, 20, 25, 30, 35);
$sides: (top, bottom, left, right, all);

@each $space in $spaceamounts {
  @each $side in $sides {
    @if $side == 'all' {
      .m#{$space} {
        margin: #{$space}px;
      }
      .my#{$space} {
        margin: #{$space}px 0;
      }
      .p#{$space} {
        padding: #{$space}px;
      }

      .py#{$space} {
        padding: #{$space}px 0;
      }
    } @else {
      .m#{str-slice($side, 0, 1)}#{$space} {
        margin-#{$side}: #{$space}px;
      }
      .p#{str-slice($side, 0, 1)}#{$space} {
        padding-#{$side}: #{$space}px;
      }
    }
  }
}

// Responsive Breakpoints for Paddings and Margins (Medium)

@media only screen and (min-width: 1024px) {
  @each $space in $spaceamounts {
    @each $side in $sides {
      @if $side == 'all' {
        .m-md-#{$space} {
          margin: #{$space}px;
        }
        .my-md-#{$space} {
          margin: #{$space}px 0;
        }
        .p-md-#{$space} {
          padding: #{$space}px;
        }

        .py-md-#{$space} {
          padding: #{$space}px 0;
        }
      } @else {
        .m-md-#{str-slice($side, 0, 1)}#{$space} {
          margin-#{$side}: #{$space}px;
        }
        .p-md-#{str-slice($side, 0, 1)}#{$space} {
          padding-#{$side}: #{$space}px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .mt-md-0 {
    margin-top: 0;
  }
}
