.request-forms {
  background: $white;
  margin: 0 20px 0px;
  padding: 20px 0px 5px;

  @media only screen and (max-width: 1200px) {
    margin: 15px;
    margin-top: 15px;
    padding: 15px;
  }

  @media (max-width: 600px) {
    margin: 15px 0;
  }

  .request-blocks-container {
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {
      display: block;
    }

    .ant-card-custom {
      background: lighten($primary, 45%);
      border: 1px solid $primary;
      margin: 10px;
      max-width: 150px;

      @media only screen and (max-width: 768px) {
        margin: auto;
        margin-bottom: 5px;
        width: 100%;
      }

      .ant-card-body {
        padding: 15px;

        @media only screen and (max-width: 768px) {
          display: flex;
          align-items: center;
          padding: 5px;
        }

        .request-blocks {
          svg {
            max-height: 60px;
          }
        }

        .request-blocks {
          @media only screen and (max-width: 768px) {
            svg {
              display: block;
              margin: 0 15px;
              width: 40px !important;
            }
          }
        }

        .ant-radio-wrapper-checked {
          background: #4758701f;
        }
      }

      .request-description {
        text-align: center;

        @media only screen and (max-width: 768px) {
          margin-right: 15px;
          max-width: 200px;
          text-align: left;
        }
      }
    }
  }

  // Autocomplete styles
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $gray-100 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}
