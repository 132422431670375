.ant-select-dropdown-menu-item {
  &:hover {
    background: $brown-600 !important;
  }
}

.ant-legacy-form-item-required {
  &::before {
    display: none !important;
  }
}

// Custom Radio buttons on cards
.radio-card-custom {
  height: 100%;
  left: 0;
  position: absolute;
  text-align: right;
  top: 0;
  width: 100%;

  .ant-radio {
    padding: 10px;
  }
}

// Input, Textarea and Select Menus according to BMW Design System

.ant-select-show-arrow {
  .ant-select-arrow {
    top: 45%;
  }

  .anticon-down {
    svg {
      display: none;
    }

    &:before {
      content: '';
      background-image: url('../../../images/gen_nav_arrow_down.svg');
      display: block;
      width: 16px;
      height: 16px;
    }
  }

  &.ant-select-open {
    .anticon-down {
      &:after {
        content: '';
        background-image: url('../../../images/gen_nav_arrow_up.svg');
        display: block;
        width: 16px;
        height: 16px;
      }

      &:before {
        display: none !important;
      }
    }
  }
}

.ant-select-search {
  .ant-input {
    border-bottom: 1px solid $gray-600 !important;
  }
}

.ant-picker {
  border-bottom: 1px solid $gray-600 !important;
}

.ant-input {
  border-bottom: 1px solid $gray-600 !important;
}

input,
textarea,
select,
.ant-picker {
  background: $gray-100;
  border: 0;
  border-radius: 0;
  color: $gray-700;
  font-size: 10px;
}

.ant-select-selector {
  background: $gray-100 !important;
  border: 0 !important;
  border-bottom: 1px solid $gray-600 !important;
  border-radius: 0 !important;
}

// Error messages

.has-error {
  input,
  textarea,
  .ant-picker,
  .ant-select-selector {
    background-color: #d200001a;
  }

  .ant-picker {
    input {
      background: transparent;
    }
  }

  .ant-input {
    &:hover {
      background-color: #d200001a;
      border-bottom: 1px solid $red !important;
    }
  }
}

.has-error {
  .ant-legacy-form-explain,
  .ant-legacy-form-split {
    color: $red;
    font-size: 0.8em;
  }
}
